<template>
	<div>
	
		<div class="content content_top_margin" style="min-height: 409px;">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
							style=" text-align:left;">
							<div class=" section_inner clearfix">
								<div class="section_inner_margin clearfix">
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h1>Ergo guide<br>
															to productivity</h1>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  info-boxes vc_custom_1636151259541"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'increase-your-energy-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="537"
																						src="../../assets/images/airbnb/Airbnb-Working-From-Office-Everyday-stretches.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Working-From-Office---Everyday-stretches"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4>
																				<router-link :to="{ name: 'increase-your-energy-a45' }">
																					Increase your energy
																				</router-link>
																			</h4>
																			<p>The best strategy for increasing
																				productivity is to manage your energy
																				rather than your time.</p>
																			<p><em>5 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'the-pomodoro-technique-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="364"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Pomodoro.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Pomodoro"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4>
																				<router-link :to="{ name: 'the-pomodoro-technique-a45' }"> 
																					The Pomodoro Technique 
																				</router-link>
																			</h4>
																			<p>Discover the Pomodoro Technique, a time
																				management strategy by Francesco
																				Cirillo.</p>
																			<p><em>5 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'sensory-overload-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Sensory-Overload.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Sensory-Overload"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4>
																				<router-link :to="{ name: 'sensory-overload-a45' }"> Sensory overload </router-link>
																				</h4>
																			<p>Check out these hacks to avoid headaches,
																				eye and ear fatigue and more.</p>
																			<p><em>5 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'productivity-hacks-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Hacks-.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity-Hacks--"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4><router-link :to="{ name: 'productivity-hacks-a45' }"> Productivity
																					hacks </router-link></h4>
																			<p>Try these unexpected hacks to help you
																				crush your workday.</p>
																			<p><em>5 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'time-management-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="501"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Time-Management.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity--Time-Management"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4>
																				<router-link :to="{ name: 'time-management-a45' }">
																					Time management 
																				</router-link>
																			</h4>
																			<p>Here are some tips to help you manage
																				your time and strike a balance between
																				work and downtime.</p>
																			<p><em>5 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'enhanced-focus-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="482"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Enhanced-Focus.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Enhanced-Focus"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h4><router-link :to="{ name: 'enhanced-focus-a45' }"> 
																				Enhanced focus 
																			</router-link>
																			</h4>
																			<p>Get focused and get productive with these
																				focus enhancing tips.</p>
																			<p><em>5 minute journey</em></p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636145552804 grid_section"
							style=" text-align:left;" v-show="company_code">
							<div class=" section_inner clearfix">
								<div class="section_inner_margin clearfix">
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  cta-banner vc_custom_1636145353757"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-12">
															<div class="vc_column-inner vc_custom_1636145566308">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>Need more help?</h2>
																			<p>Take a self-assessment today and see<br>
																				how ergo can help you feel better
																				and<br>
																				work smarter.</p>
																			<p>
																				<router-link :to="{ name: 'get-an-assessment-a45' }" class="button">
																				Take a self-assessment </router-link></p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
				

			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'productivity-a45',
		data () {
			return {
			company_code: true,      
			}
		},
		mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
			let data = this.$store.getters["auth/authUser"];
			let company_data = data.client;
			if(company_data.self_assessment_button_status == 0){
				this.company_code = false;
			}
		},
	}
</script>